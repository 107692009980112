import '../../css/About.css'
import { motion } from "framer-motion";
import { useTranslation } from 'react-i18next';

const Work = () => {
    const { t } = useTranslation();

    return (
        <>
            <motion.div className="informationBox" id={"work"}
                        animate={{ scale: [0.5, 1.0]}}
                        transition={{ duration: 0.5 }}
            >
                <h2 className="aboutTitle">{t('workTitle')}</h2>
                <div className="about">
                    <p className="descriptionAbout">
                        {t('workDescription')}
                    </p>
                </div>
            </motion.div>
        </>
    );
};

export default Work;