import "../../css/Projects.css"
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Projects = () => {
    const { t } = useTranslation();

    return (
        <div className={"projectsBox"} id={"projects"}>
            <motion.h1 className={"aboutTitle"}
                       animate={{ x: [-100, 0] }}
                       transition={{ delay: 0.1 }}
            >
                {t('projectsTitle')}
            </motion.h1>
            <Link to="/minecraftClone">
                <motion.div className={"projects"}
                            transition={{ duration: 0.3 }}
                            animate={{ x: [-500, 0] }}
                            whileHover={{
                                scale: 1.05
                            }}
                >
                    <div className={"innerPDesc"}>
                        <h2>{t('minecraftCloneTitle')}</h2>
                        <p>{t('minecraftCloneDescription')}</p>
                        <span className={"foot"}><b>{t('usedTechnologies')}</b>C++, OpenGL</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="minecraftCloneImage" src={require("../../images/minecraftClone/Screenshot.png")}/>
                    </div>
                </motion.div>
            </Link>
            <Link to="/braintumor">
                <motion.div className={"projects"}
                            transition={{ duration: 0.3 }}
                            animate={{ x: [-500, 0] }}
                            whileHover={{
                                scale: 1.05
                            }}
                >
                    <div className={"innerPDesc"}>
                        <h2>{t('brainTumorTitle')}</h2>
                        <p>{t('brainTumorDescription')}</p>
                        <span className={"foot"}><b>{t('usedTechnologies')}</b>Python, Tensorflow, Keras</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="brainImage" src={require("../../images/braintumor/braintumor_mask.png")}/>
                    </div>
                </motion.div>
            </Link>
            <Link to="/catbreed">
                <motion.div className={"projects"}
                            transition={{ duration: 0.3 }}
                            animate={{ x: [500, 0] }}
                            whileHover={{
                                scale: 1.05
                            }}
                >
                    <div className={"innerPDesc"}>
                        <h2>{t('catBreedTitle')}</h2>
                        <p>{t('catBreedDescription')}</p>
                        <span className={"foot"}><b>{t('usedTechnologies')}</b>Python, Tensorflow, Keras</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="catBreedImage" src={require("../../images/catbreed/CatBreed1.jpg")}/>
                    </div>
                </motion.div>
            </Link>
            <Link to="/spikyball">
                <motion.div className={"projects"}
                            transition={{ duration: 0.3 }}
                            animate={{ x: [-500, 0] }}
                            whileHover={{
                                scale: 1.05
                            }}
                >
                    <div className={"innerPDesc"}>
                        <h2>{t('spikyBallTitle')}</h2>
                        <p>{t('spikyBallDescription')}</p>
                        <span className={"foot"}><b>{t('usedTechnologies')}</b>C#, Unity</span>
                    </div>
                    <div className={"innerPImg"}>
                        <img className="spikyBallImage" src={require("../../images/spikyBall/SpikyBall2.png")}/>
                    </div>
                </motion.div>
            </Link>
        </div>
    );
};

export default Projects;