import '../../css/Home.css'
import About from "./About";
import Projects from "./Projects";
import { motion } from "framer-motion";
import Contact from "./Contact";
import Work from "./Work";
import { useTranslation } from 'react-i18next';

const Home = () => {
    const { t } = useTranslation();

    return (
        <>
            <div id={"home"}>
                <motion.h1 className={"title"}
                           animate={{ x: [-100, 0]}}
                >{t('title')}</motion.h1>

                <motion.div className="description"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <p>{t('description')}</p>
                </motion.div>
            </div>
            <About/>
            <Work/>
            <Projects/>
            <Contact/>
        </>
    );
};

export default Home;