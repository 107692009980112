import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    "title": "Hi, I am Martin, Computer Science student.",
                    "description": "On my portfolio, I proudly present my numerous projects where I have demonstrated my skills in web development, software engineering, and game development. If you are interested in the tech industry or just want to admire my work, you are in the right place.",
                    "aboutTitle": "About Me",
                    "aboutDescription": "My name is Martin and I have been fascinated by the world of computers since my childhood. Early on, I started developing my own games, and this passion has stayed with me to this day. I am now deeply involved in topics such as neural networks and machine learning, and have gained extensive experience in various programming languages.\n\nFor example, I have extensive knowledge in Java and Python, the latter of which I also use in conjunction with Keras and Tensorflow. Additionally, I am familiar with C++, C#, and the Unreal Engine. Overall, I have a broad range of skills and competencies in software development.\n\nI am convinced that my passion and experience can help me realize even larger projects and find innovative solutions in the future. Therefore, I look forward to further expanding my knowledge in this area and taking on new challenges.",
                    "contactTitle": "Have I piqued your interest?",
                    "contactDescription1": "If you are looking for a dedicated and reliable working student or intern, then I might be the right person for you. My website has already given you an impression of my skills and experiences. Should you be interested in a collaboration or need further information, I am always available.",
                    "contactDescription2": "I am currently specifically looking for a new challenge in the form of a working student job or internship to further develop my skills and gain practical experience. If you see a corresponding opportunity in your company, I would be very happy to hear from you and learn more about your expectations and requirements.",
                    "contactDescription3": "You can find links to my LinkedIn and GitHub profiles in the header of this website.",
                    "contactButton": "Contact",
                    "navAbout": "About Me",
                    "navWork": "Work Experience",
                    "navProjects": "Projects",
                    "navContact": "Contact",
                    "projectsTitle": "Notable Projects:",
                    "minecraftCloneTitle": "MinecraftClone",
                    "minecraftCloneDescription": "A low-level voxel project in C++ and OpenGL focusing on memory management, pointer usage, and resource-efficient algorithms.",
                    "brainTumorTitle": "Brain Tumor Segmentation",
                    "brainTumorDescription": "I recently completed a project where I modeled a neural network, specifically a U-Net, to colorize brain tumors on MRI images. This method makes the tumors much more visible.",
                    "catBreedTitle": "Cat Breed Recognition",
                    "catBreedDescription": "Cat breed recognition using a Convolutional Neural Network (CNN) and transfer learning through the InceptionV3 model.",
                    "spikyBallTitle": "Spiky Ball",
                    "spikyBallDescription": "A simple game with procedurally generated levels where the player controls a ball on a cylinder and avoids obstacles.",
                    "usedTechnologies": "Used Programming Languages / Libraries: ",
                    "workTitle": "Working Student at ipoque (Rohde & Schwarz)",
                    "workDescription": "Currently, I work as a working student at ipoque, a subsidiary of Rohde & Schwarz, in the field of Deep Packet Inspection (DPI). I develop high-performance solutions in C for analyzing and processing network traffic at the packet level.\n\nA central part of my work is analyzing network traces to identify patterns and protocols. This allows me to accurately classify new applications and services and add them to the existing detection.\n\nThe job allows me to further develop my skills in low-level programming, network protocols, and performance optimization. Due to an NDA, I cannot share further details about the projects, but the experience is an exciting challenge for me."
                }
            },
            de: {
                translation: {
                    "title": "Hi, ich bin Martin, Informatikstudent.",
                    "description": "Auf meinem Portfolio stelle ich stolz meine zahlreichen Projekte vor, bei denen ich mein Können in Web-Entwicklung, Software-Engineering und Spieleentwicklung unter Beweis gestellt habe. Wenn du an der Tech-Industrie interessiert bist oder einfach nur meine Arbeit bewundern möchtest, bist du hier genau richtig.",
                    "aboutTitle": "Über mich",
                    "aboutDescription": "Ich heiße Martin und bin seit meiner Kindheit begeistert von der Welt der Computer. Schon früh begann ich damit, meine eigenen Spiele zu entwickeln, und diese Leidenschaft hat mich bis heute nicht losgelassen. Inzwischen beschäftige ich mich intensiv mit Themen wie neuronalen Netzen und machinellem Lernen, und habe bereits umfassende Erfahrungen in verschiedenen Programmiersprachen gesammelt.\n\nSo habe ich beispielsweise umfangreiche Kenntnisse in Java und Python, wobei ich letztere Sprache auch in Verbindung mit Keras und Tensorflow nutze. Darüber hinaus bin ich auch mit C++, C# und der Unreal Engine vertraut. Insgesamt betrachtet verfüge ich also über ein breites Spektrum an Fähigkeiten und Kompetenzen im Bereich der Softwareentwicklung.\n\nIch bin davon überzeugt, dass meine Leidenschaft und meine Erfahrung mir dabei helfen können, in Zukunft noch größere Projekte zu realisieren und innovative Lösungen zu finden. Daher freue ich mich darauf, meine Kenntnisse in diesem Bereich weiter auszubauen und immer wieder neue Herausforderungen anzunehmen.",
                    "contactTitle": "Habe ich Ihr Interesse geweckt?",
                    "contactDescription1": "Wenn Sie auf der Suche nach einem engagierten und zuverlässigen Werkstudenten oder Praktikanten sind, dann könnte ich die richtige Person für Sie sein. Meine Webseite hat Ihnen bereits einen Eindruck von meinen Fähigkeiten und Erfahrungen vermittelt. Sollten Sie Interesse an einer Zusammenarbeit haben oder weitere Informationen benötigen, stehe ich Ihnen jederzeit gerne zur Verfügung.",
                    "contactDescription2": "Ich suche derzeit gezielt nach einer neuen Herausforderung in Form eines Werkstudentenjobs oder Praktikums, um meine Fähigkeiten weiter auszubauen und praktische Erfahrungen zu sammeln. Sollten Sie eine entsprechende Möglichkeit in Ihrem Unternehmen sehen, würde ich mich sehr freuen, von Ihnen zu hören und mehr über Ihre Erwartungen und Anforderungen zu erfahren.",
                    "contactDescription3": "Link zu meinem LinkedIn und GitHub Profil finden Sie im Header dieser Webseite.",
                    "contactButton": "Kontakt",
                    "navAbout": "Über mich",
                    "navWork": "Berufserfahrung",
                    "navProjects": "Projekte",
                    "navContact": "Kontakt",
                    "projectsTitle": "Nennenswerte Projekte:",
                    "minecraftCloneTitle": "MinecraftClone",
                    "minecraftCloneDescription": "Ein Low-Level-Voxel-Projekt in C++ und OpenGL mit Fokus auf Speicherverwaltung, Pointer-Nutzung und ressourcenschonende Algorithmen.",
                    "brainTumorTitle": "Hirntumor Segmentierung",
                    "brainTumorDescription": "Ich habe kürzlich ein Projekt abgeschlossen, bei dem ich ein neuronales Netz, genauer ein U-Net, modelliert habe, um Hirntumore auf MRT-Bildern einzufärben. Mit dieser Methode können die Tumore deutlich sichtbarer gemacht werden.",
                    "catBreedTitle": "Katzenrassen Erkennung",
                    "catBreedDescription": "Katzenrassenerkennung mit einem Convolutional Neural Network (CNN) und Transfer Learning durch das InceptionV3-Modell.",
                    "spikyBallTitle": "Spiky Ball",
                    "spikyBallDescription": "Ein simples Spiel mit prozedural generierten Leveln, bei dem der Spieler einen Ball auf einem Zylinder steuert und Hindernissen ausweicht.",
                    "usedTechnologies": "Verwendete Programmiersprachen / Libraries: ",
                    "workTitle": "Werkstudententätigkeit bei ipoque (Rohde & Schwarz)",
                    "workDescription": "Derzeit arbeite ich als Werkstudent bei ipoque, einer Tochter von Rohde & Schwarz, im Bereich Deep Packet Inspection (DPI). Dabei entwickle ich in C leistungsfähige Lösungen zur Analyse und Verarbeitung von Netzwerkverkehr auf Paketebene.\n\nEin zentraler Bestandteil meiner Arbeit ist die Analyse von Netzwerk-Traces, um Muster und Protokolle zu identifizieren. Dadurch kann ich neue Anwendungen und Dienste präzise klassifizieren und zur bestehenden Erkennung hinzufügen.\n\nDie Tätigkeit gibt mir die Möglichkeit, meine Fähigkeiten in Low-Level-Programmierung, Netzwerkprotokollen und Performance-Optimierung weiter auszubauen. Aufgrund einer NDA kann ich keine weiteren Details zu den Projekten teilen, aber die Erfahrung ist für mich eine spannende Herausforderung."
                }
            }
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;