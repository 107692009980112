import '../../css/Contact.css'
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation();

    return(
        <div id={"contact"}>
            <div className={"contactBox"}>
                <h2>{t('contactTitle')}</h2>
                <p>{t('contactDescription1')}</p>
                <p>{t('contactDescription2')}</p>
                <p>{t('contactDescription3')}</p>
                <div id={"buttonBox"}>
                    <a href="mailto:hertel.martin01@gmail.com">{t('contactButton')}</a>
                </div>
            </div>
        </div>
    );
};

export default Contact;